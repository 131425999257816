import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import dynamic from "next/dynamic";
import Script from "next/script";

const DefaultFooterMenu = dynamic(() => import("./DefaultFooterMenu"));
const ReserveFooterMenu = dynamic(() => import("./ReserveFooterMenu"));

const FooterMenu = ({ property }) => {
  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
          {property?.property ? (
            <ReserveFooterMenu property={property} />
          ) : (
            <DefaultFooterMenu />
          )}
        </Box>
      </Paper>
        <Script id="google-analytics" strategy="lazyOnload">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-0ZMLL6N3DV');
        `}
        </Script>
        <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-0ZMLL6N3DV"
            strategy="afterInteractive"
        />
    </>
  );
};

export default FooterMenu;
